<template>
  <v-card id="account-setting-card">
    <v-card-title class="justify-center">
      <span class="text-h5"><strong>ADVERSE REACTION REPORT</strong></span>
    </v-card-title>
    <!-- tabs -->
    <!-- <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs> -->
     <v-tabs v-model="active_tab">
      <v-tab
        key="0"
        :disabled="readOnlyField === true"
      >
        <span>OS (oculus sinister)</span>
      </v-tab>
      <v-tab
        key="1"
        :disabled="readOnlyField1 === true"
      >
        
        <span>OD (oculus dextrus)</span>
      </v-tab>
      </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="active_tab">
      <v-tab-item key="0">
        <AdverseReactionOS/>
      </v-tab-item>

      <v-tab-item key="0">
        <AdverseReactionOD />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

// demos
import adversesevice from '@/service/AdverseReaction.service'
import AdverseReactionOS from "./AdverseReactionOS.vue"
import AdverseReactionOD from "./AdverseReactionOD.vue"


export default {
  components: {
    AdverseReactionOS,
    AdverseReactionOD,
   
  },
  
  setup() {
   return {
      readOnlyField: false,
      readOnlyField1: false,
      active_tab: 0,
    }
  },

  watch: {
    options: {
      handler() {
        this.AdverseList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.AdverseList()
  },

  methods:{
      async AdverseList() {
      const DonorId = this.$route.params.donor_id
      const service = new adversesevice()
      let response = await service.AdverseReaction_OD(DonorId)
      // console.log("OSSS", response)
       if(response.os_id == null){
        //console.log("shubham")
        this.readOnlyField = true
        this.active_tab = "1"
      }if(response.od_id == null){
        //console.log("bhati")
        this.readOnlyField1 = true
        this.active_tab = "0"
      }if(response.os_id == null && response.od_id == null){
        //console.log("bhati new")
        this.readOnlyField = true
        this.readOnlyField1 = true
        this.active_tab = "2"
      }
    }
  }

}

</script>

	

