<template>
  <v-card>
    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Surgery Information</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Surgeon Name:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.surgeon_name" dense hide-details ></v-text-field>
        <!-- <v-autocomplete v-model="AdverseReactionData.surgeon_name" dense hide-details :items="surgeonName" item-text="surgeon_name"> </v-autocomplete> -->
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Surgery Location:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.surgery_location" dense hide-details readonly></v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Phone No:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.hospital_phone" dense hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
    </v-row>

    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Donor Information</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="4">
        <v-text-field v-model="Distributing" label="Distributing Eye Bank" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.tissue_id" label="Tissue ID" dense hide-details readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.donor_age" label="Donor Age" dense hide-details @keypress="onlyAge" readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.cause_of_death" label="cause of Death" dense hide-details readonly ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-datetime-picker v-model="preservationDateTime" time-format="HH:mm" date-format="dd-MM-yyyy" label="Preservation Date and Time" :text-field-props="textFieldProps" readonly>
        </v-datetime-picker>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.death_to_preservation_time" label="Death-preservation interval(in Hrs)" @keypress="onlyNumber" dense hide-details readonly>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-datetime-picker v-model="surgeryDateTime" time-format="HH:mm" date-format="dd-MM-yyyy" label="Surgery Date Time" :text-field-props="textFieldProps" :readonly="readOnlyField1 === true">
        </v-datetime-picker>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.death_surgery_interval" label="Death-Surgery Interval" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.preservation_medium" label="Preservation Medium" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.media_lot_no" label="Media Lot Number" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
    </v-row>

    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Tissue Source Information</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="4">
        <v-text-field label="Tissue Obtained from *" v-model="AdverseReactionData.tissue_obtained_from" dense hide-details :readonly="readOnlyField1 === true" :error="errr === false"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Recipient Id" v-model="AdverseReactionData.recipient_ID" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Tissue Distributed*" v-model="AdverseReactionData.tissue_distribution" dense hide-details :readonly="readOnlyField1 === true" :error="errr1 === false"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Imported Tissue*" v-model="AdverseReactionData.imported_tissue" dense hide-details :readonly="readOnlyField1 === true" :error="errr2 === false"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Origin Eye Bank" v-model="AdverseReactionData.origin_eye_bank" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Origin Eye Bank#" v-model="AdverseReactionData.origin_eye_bank1" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
    </v-row>
    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Recipient Information</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.patient_name" label="Patient Name" dense hide-details ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Patient ID/SSN (Aadhar or PAN No.)" v-model="AdverseReactionData.patient_id" @keypress="onlyNumber1" dense hide-details ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Age" v-model="AdverseReactionData.patient_age" dense hide-details @keypress="onlyAge" ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete dense hide-details :items="Gender" v-model="AdverseReactionData.patient_gender" label="Gender" > </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Pre-Operative Diagnosis" v-model="AdverseReactionData.pre_operative_diagnosis" dense hide-details ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-datetime-picker time-format="HH:mm" date-format="dd-MM-yyyy" v-model="surgeryDateTime" label="Surgery Date Time" :text-field-props="textFieldProps">
        </v-datetime-picker>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Procedure" v-model="AdverseReactionData.recipient_procedure" dense hide-details ></v-text-field >
      </v-col>
    </v-row>
    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Recipient Outcome</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-9">
      <v-col cols="12" md="4">
        <v-autocomplete v-model="AdverseReactionData.additional_surgery" dense hide-details :items="Additional" label="Additional Surgery(Check Applicable)" :readonly="readOnlyField1 === true" :error="errr3 === false">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete v-model="AdverseReactionData.recent_vision" dense hide-details :items="Recent" label="Recent Vision(Check Applicable)" :readonly="readOnlyField1 === true" :error="errr4 === false"> </v-autocomplete>
      </v-col>
    </v-row>

    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Adverse Reaction</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="4">
        <v-datetime-picker v-model="DateandTimeDiagnosis" time-format="HH:mm" date-format="dd-MM-yyyy" label="Date and Time Diagnosis*" :readonly="readOnlyField1 === true" :error="errr5 === false"> </v-datetime-picker>
      </v-col>
      <v-col cols="12" md="8"></v-col>
      <v-col cols="12" md="4">
        <v-autocomplete v-model="AdverseReactionData.adverse_reaction" dense hide-details :items="CheckOne" label="Check One*" :readonly="readOnlyField1 === true" :error="errr6 === false"> </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" v-if="AdverseReactionData.adverse_reaction == 'other'">
        <v-autocomplete v-model="AdverseReactionData.adverse_reaction_other" dense hide-details :items="Checkother" label="Check One" :readonly="readOnlyField1 === true"> </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="4">
        <p class="body-2 mt-2 mb-0">Brief Description of Reaction including Treatment and outcome:</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-textarea v-model="AdverseReactionData.brief_description_of_reaction" hide-details row-height="15" rows="2" class="pt-0 mt-0" :readonly="readOnlyField1 === true"></v-textarea>
      </v-col>
    </v-row>

    <v-row class="ml-3 mr-5 pt-3 mb-3"> </v-row>
    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Microbiology</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="4">
        <v-autocomplete v-model="AdverseReactionData.donor_cultures" dense hide-details :items="Cultures" label="Donor Cultures(Check Applicable)*" :readonly="readOnlyField1 === true" :error="errr7 === false"> </v-autocomplete>
      </v-col>
       <v-col cols="12" md="4">
        <v-text-field label="Results" v-model="AdverseReactionData.donor_cultures_result" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete v-model="AdverseReactionData.patient_cultures" dense hide-details :items="PatientCultures" label="Patient Cultures(Check Applicable)*" :readonly="readOnlyField1 === true" :error="errr8 === false">
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="ml-3 mr-5 pt-3 mb-3"> </v-row>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="5">
        <v-file-input v-model="signature" chips accept="image/*" label="Surgeon Sinature*" @change="onFile" :readonly="readOnlyField1 === true" :error="errr9 === false" />
        <!-- <img v-img style="width:70%" :src="`https://api.stage.ebsr.in/${AdverseReactionData.surgeon_signature}`" /> -->
         <v-col v-if="!signatureObj && AdverseReactionData.surgeon_signature" cols="7">
          <img  v-img style="width:85%" :src="!signatureObj?`https://api.stage.ebsr.in/${AdverseReactionData.surgeon_signature}`:''" />
       </v-col>
        <v-col v-if="!!signatureObj"  cols="7">
            <img v-img style="width: 85%;" :src="signatureObj?signatureObj:''" />
        </v-col>
      </v-col>
      <v-col cols="12" md="3"></v-col>
      <v-col cols="12" md="4">
        <v-datetime-picker v-model="DateTimeAbs" time-format="HH:mm" date-format="dd-MM-yyyy" label="Date and Time" :readonly="readOnlyField1 === true"> </v-datetime-picker>
      </v-col>
    </v-row>

    <v-card-title class="justify-start">
      <span class="font-weight-semibold">This section to be completed by the Eye Bank</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Date-time Received at Eye bank*:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-datetime-picker v-model="ReceivedAtEyeBank" time-format="HH:mm" date-format="dd-MM-yyyy" :readonly="readOnlyField1 === true" :error="errr10 === false"> </v-datetime-picker>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Mate Cornea Tissue Number*:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="AdverseReactionData.mate_cornea_tissue_number" hide-details @keypress="onlyNumber" @paste.prevent :readonly="readOnlyField1 === true" :error="errr11 === false"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete v-model="AdverseReactionData.mate_cornea_status" dense hide-details :readonly="readOnlyField1 === true" :items="CorneaStatue" label="Mate Cornea statue*:" :error="errr12 === false">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" v-if="AdverseReactionData.mate_cornea_status == 'Tissue Not Used'">
        <v-text-field v-model="AdverseReactionData.mate_cornea_reason" label="Reason" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="mb-5" v-if="saveBtn" @click="same()"> Save </v-btn>
    </v-card-actions>
     <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import donorService from '@/service/Donor.service'
import AdverseReactionService from '@/service/AdverseReaction.service'
import moment from 'moment';
const localData = JSON.parse(localStorage.getItem("token"));
const token = localData.token;
import { api } from '@/config/config'

export default {
  data() {
    return {
       errr:true,
      errr1:true,
      errr2:true,
      errr3:true,
      errr4:true,
      errr5:true,
      errr6:true,
      errr7:true,
      errr8:true,
      errr9:true,
      errr10:true,
      errr11:true,
      errr12:true,
      formtype:'',
      readOnlyField1:false,
      saveBtn:true,
      Distributing:'Eye Bank Society of Rajasthan',
      mate_cornea_status: '',
      signature: null,
      image: null,
      listLoading: false,
      timeout: 2000,
      snackbar: false,
      snackbarText: '',
      adverse_reaction: '',
      Gender: ['Male', 'Female'],
      Checkother: ['Probably Due to Donor Tissue', 'Probably Not Due to Donor Tissue'],
      CheckOne: [
        'Primary Graft failure',
        'Intraocular infection(Microbial Endophthalmitis)',
        'Corneal infection(Microbial or Viral Keratitis)',
        'Transmission of Systemic of viral disease',
        'Evidence Suggestive of Prior Refractive Surgery',
        'other',
      ],
      Additional: ['Regraft', 'Enucleation', 'Other'],
      Recent: ['No Light Perception', 'Light Perception-counting Fingers', '6/60 Or Better'],
      Cultures: ['Preservation Medium', 'Corneoscleral', 'Other', 'Not Done'],
      PatientCultures: ['Aqueous', 'Cornea', 'Vitreous', 'Other', 'Not Done'],
      textFieldProps: {
        dense: 'true',
        'hide-details': 'true',
      },
      CorneaStatue: ['No Adverse Reaction', 'Adverse Reaction', 'Tissue Not Used'],
      AdverseReactionData:{
        surgery_date_time:'',
        date_of_diagnosis:'',
        microbiology_date:'',
        AdverseReactionData:'',
        surgeon_signature:null,
        date_time_received_at_eye_bank:'',
        tissue_id:'',
        hospital_phone:null,
        tissue_obtained_from:'',
        recipient_ID:'',
        tissue_distribution:'',
        imported_tissue:'',
        origin_eye_bank:'',
        origin_eye_bank1:'',
        additional_surgery:'',
        recent_vision:'',
        adverse_reaction:'',
        adverse_reaction_other:'',
        brief_description_of_reaction:'',
        donor_cultures:'',
        patient_cultures:'',
        mate_cornea_tissue_number:'',
        mate_cornea_status:'',
        mate_cornea_reason:'',
        signatureObj:'',
        patient_id:'',
        death_surgery_interval:'',
        patient_name:'',
        patient_age:'',
        patient_gender:'',
        pre_operative_diagnosis:'',
        recipient_procedure:'',
        surgeon_name:'',


      },
    }
  },
  computed:{
    surgeryDateTime: {
      get() {
        return this.AdverseReactionData.surgery_date_time && moment.utc(this.AdverseReactionData.surgery_date_time).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.AdverseReactionData, "surgery_date_time", moment(value).format(
          "YYYY-MM-DD HH:mm"
        ));
      },
    },

    DateandTimeDiagnosis: {
      get() {
        return this.AdverseReactionData.date_of_diagnosis && moment.utc(this.AdverseReactionData.date_of_diagnosis).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.AdverseReactionData, "date_of_diagnosis", moment(value).format(
          "YYYY-MM-DD HH:mm"
        ));
      },
    },

    DateTimeAbs: {
      get() {
        return this.AdverseReactionData.microbiology_date && moment.utc(this.AdverseReactionData.microbiology_date).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.AdverseReactionData, "microbiology_date", moment(value).format(
          "YYYY-MM-DD HH:mm"
        ));
      },
    },

    ReceivedAtEyeBank: {
      get() {
        return this.AdverseReactionData.date_time_received_at_eye_bank && moment.utc(this.AdverseReactionData.date_time_received_at_eye_bank).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.AdverseReactionData, "date_time_received_at_eye_bank", moment(value).format(
          "YYYY-MM-DD HH:mm"
        ));
      },
    },

    preservationDateTime: {
      get() {
        return this.AdverseReactionData.preservation_date_time && moment.utc(this.AdverseReactionData.preservation_date_time).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.AdverseReactionData, "preservation_date_time", moment(value).format(
          "YYYY-MM-DD HH:mm"
        ));
      },
    },
    

  },
   watch: {
    options: {
      handler() {
        this.FetchAdverseReaction()
        this.getdonorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.FetchAdverseReaction()
    this.getdonorList()
  },

  methods: {
    same(){
      //  if(this.AdverseReactionData.tissue_obtained_from == "" || this.AdverseReactionData.tissue_obtained_from == null){
      //   this.errr = false
      //   return false,
      //   this.snackbarText = "Please Fill the Tissue obtained from",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.tissue_distribution == "" || this.AdverseReactionData.tissue_distribution == null){
      //   this.errr1 =  false
      //   return false,
      //   this.snackbarText = "Please Fill the Tissue distribution",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.imported_tissue == "" || this.AdverseReactionData.imported_tissue == null){
      //   this.errr2 = false
      //   return false,
      //   this.snackbarText = "Please Fill the Imported tissue",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.additional_surgery == "" || this.AdverseReactionData.additional_surgery == null){
      //   this.errr3 = false
      //   return false,
      //   this.snackbarText = "Please Select Additional surgery",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.recent_vision == "" || this.AdverseReactionData.recent_vision == null){
      //   this.errr4 = false
      //   return false,
      //   this.snackbarText = "Please Select Recent vision",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.date_of_diagnosis == "" || this.AdverseReactionData.date_of_diagnosis == null){
      //   this.errr5 = false
      //   return false,
      //   this.snackbarText = "Please Select Date of diagnosis",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.adverse_reaction == "" || this.AdverseReactionData.adverse_reaction == null){
      //   this.errr6 = false
      //   return false,
      //   this.snackbarText = "Please Select Check one",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.donor_cultures == "" || this.AdverseReactionData.donor_cultures == null){
      //   this.errr7 = false
      //   return false,
      //   this.snackbarText = "Please Select Donor Cultures",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.patient_cultures == "" || this.AdverseReactionData.patient_cultures == null){
      //   this.errr8 = false
      //   return false,
      //   this.snackbarText = "Please Select Patient Cultures",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.surgeon_signature == "" || this.AdverseReactionData.surgeon_signature == null){
      //   this.errr9 = false
      //   return false,
      //   this.snackbarText = "Please Fill the Surgeon Signature",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.date_time_received_at_eye_bank == "" || this.AdverseReactionData.date_time_received_at_eye_bank == null){
      //   this.errr10 = false
      //   return false,
      //   this.snackbarText = "Please Fill the Date time received at eye bank",
      //   this.snackbar = true
      // }
      // else if(this.AdverseReactionData.mate_cornea_tissue_number == "" || this.AdverseReactionData.mate_cornea_tissue_number == null){
      //   this.errr11 = false
      //   return false,
      //   this.snackbarText = "Please Fill the mate cornea tissue number",
      //   this.snackbar = true
      // }
      if(this.AdverseReactionData.preservation_date_time == "" || this.AdverseReactionData.preservation_date_time === null || this.AdverseReactionData.preservation_date_time == "Invalid date"){
        // this.errr12 = false
        return false,
        this.snackbarText = "Please Fill the preservation date and time",
        this.snackbar = true
      }
      else if(this.AdverseReactionData.date_of_diagnosis == "" || this.AdverseReactionData.date_of_diagnosis === null || this.AdverseReactionData.date_of_diagnosis == "Invalid date"){
        this.AdverseReactionData.date_of_diagnosis = null
      }
      else if(this.AdverseReactionData.microbiology_date == "" || this.AdverseReactionData.microbiology_date === null || this.AdverseReactionData.microbiology_date == "Invalid date"){
        this.AdverseReactionData.microbiology_date = null
      }
      else if(this.AdverseReactionData.date_time_received_at_eye_bank == "" || this.AdverseReactionData.date_time_received_at_eye_bank === null || this.AdverseReactionData.date_time_received_at_eye_bank == "Invalid date"){
        this.AdverseReactionData.date_time_received_at_eye_bank = null
      }
      else{
        this.AdverseReactionData.date_of_diagnosis = moment.utc(this.AdverseReactionData.date_of_diagnosis).format('YYYY-MM-DD HH:mm')
        this.AdverseReactionData.microbiology_date = moment.utc(this.AdverseReactionData.microbiology_date).format('YYYY-MM-DD HH:mm')
        this.AdverseReactionData.date_time_received_at_eye_bank = moment.utc(this.AdverseReactionData.date_time_received_at_eye_bank).format('YYYY-MM-DD HH:mm')
        // this.errr = true
        // this.errr1 = true
        // this.errr2 = true
        // this.errr3 = true
        // this.errr4 = true
        // this.errr5 = true
        // this.errr6 = true
        // this.errr7 = true
        // this.errr8 = true
        // this.errr9 = true
        // this.errr10 = true
        // this.errr11 = true
        // this.errr12 = true
      }
      this.addAdverseReaction()
      // if(this.formtype.view != '0' && this.formtype.view != null){
      //   this.$router.push({name:'Recipient'})

      // }
},
    onFile() {
      this.signatureObj=URL.createObjectURL(this.signature)
      const reader = new FileReader();
      reader.readAsDataURL(this.signature);
      reader.onload = (e) => {
        this.AdverseReactionData.surgeon_signature = e.target.result;
      };
    },


     async FetchAdverseReaction() {
      const DonorId = this.$route.params.donor_id
      const service = new AdverseReactionService()
      let response = await service.AdverseReaction_OD(DonorId)
      this.AdverseReactionData = response
      // console.log("hello",response)
  
    },

     async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formData = this.permissionData[11]
      if(formData.view == "1" && formData.edit == null || formData.view == "1" && formData.edit == "0"){
          this.readOnlyField1 = true
          this.saveBtn = false
        } 
      }else {
      this.totallist = 0
      }
      this.listLoading = false
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault()
      }
    },
     onlyNumber1($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 14) {
        $event.preventDefault()
      }
    },
    onlyAge($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2) {
        $event.preventDefault()
      }
    },

    async addAdverseReaction() {

      if(this.AdverseReactionData.date_of_diagnosis == "" || this.AdverseReactionData.date_of_diagnosis === null || this.AdverseReactionData.date_of_diagnosis == "Invalid date"){
        this.AdverseReactionData.date_of_diagnosis = null
      }
      else if(this.AdverseReactionData.microbiology_date == "" || this.AdverseReactionData.microbiology_date === null || this.AdverseReactionData.microbiology_date == "Invalid date"){
        this.AdverseReactionData.microbiology_date = null
      }
      else if(this.AdverseReactionData.date_time_received_at_eye_bank == "" || this.AdverseReactionData.date_time_received_at_eye_bank === null || this.AdverseReactionData.date_time_received_at_eye_bank == "Invalid date"){
        this.AdverseReactionData.date_time_received_at_eye_bank = null
      }
      else if(this.AdverseReactionData.surgery_date_time == "" || this.AdverseReactionData.surgery_date_time === null || this.AdverseReactionData.surgery_date_time == "Invalid date"){
        this.AdverseReactionData.surgery_date_time = null
      }
      else{
        this.AdverseReactionData.date_of_diagnosis = moment.utc(this.AdverseReactionData.date_of_diagnosis).format('YYYY-MM-DD HH:mm')
        this.AdverseReactionData.microbiology_date = moment.utc(this.AdverseReactionData.microbiology_date).format('YYYY-MM-DD HH:mm')
        this.AdverseReactionData.date_time_received_at_eye_bank = moment.utc(this.AdverseReactionData.date_time_received_at_eye_bank).format('YYYY-MM-DD HH:mm')
        this.AdverseReactionData.surgery_date_time = moment.utc(this.AdverseReactionData.surgery_date_time).format('YYYY-MM-DD HH:mm')
      }
       
      const donorid = this.$route.params.donor_id;
      const data = {
        tissue_id:this.AdverseReactionData.tissue_id,
        donor_id:donorid,
        OD_and_OS:'OD',
        hospital_phone:this.AdverseReactionData.hospital_phone,
        tissue_obtained_from:this.AdverseReactionData.tissue_obtained_from,
        recipient_ID:this.AdverseReactionData.recipient_ID,
        tissue_distribution:this.AdverseReactionData.tissue_distribution,
        imported_tissue:this.AdverseReactionData.imported_tissue,
        origin_eye_bank:this.AdverseReactionData.origin_eye_bank,
        origin_eye_bank1:this.AdverseReactionData.origin_eye_bank1,
        additional_surgery:this.AdverseReactionData.additional_surgery,
        recent_vision:this.AdverseReactionData.recent_vision,
        date_of_diagnosis: this.AdverseReactionData.date_of_diagnosis,
        adverse_reaction:this.AdverseReactionData.adverse_reaction,
        adverse_reaction_other:this.AdverseReactionData.adverse_reaction_other,
        brief_description_of_reaction:this.AdverseReactionData.brief_description_of_reaction,
        donor_cultures:this.AdverseReactionData.donor_cultures,
        patient_cultures:this.AdverseReactionData.patient_cultures,
        surgeon_signature:this.AdverseReactionData.surgeon_signature,
        microbiology_date: this.AdverseReactionData.microbiology_date,
        date_time_received_at_eye_bank: this.AdverseReactionData.date_time_received_at_eye_bank,
        mate_cornea_tissue_number:this.AdverseReactionData.mate_cornea_tissue_number,
        mate_cornea_status:this.AdverseReactionData.mate_cornea_status,
        mate_cornea_reason:this.AdverseReactionData.mate_cornea_reason,
        patient_name:this.AdverseReactionData.patient_name,
        patient_age:this.AdverseReactionData.patient_age,
        patient_gender:this.AdverseReactionData.patient_gender,
        pre_operative_diagnosis:this.AdverseReactionData.pre_operative_diagnosis,
        recipient_procedure:this.AdverseReactionData.recipient_procedure,
        death_surgery_interval:this.AdverseReactionData.death_surgery_interval,
        patient_id:this.AdverseReactionData.patient_id,
        surgeon_name:this.AdverseReactionData.surgeon_name,
        surgery_date_time: this.AdverseReactionData.surgery_date_time
      };
      try {
        const response = await api.post(`adverseReaction/add`,data,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status == 200) {
          this.snackbarText = response.data.msg;
          this.getretrieval();
        } else {
          this.snackbarText = response.data.msg;
        }
        this.snackbar = true;
      } catch (e) {
        console.log(e);
      }
    },
  },
}
</script>
